/* eslint-disable no-nested-ternary */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * React container component for product
 *
 * @file ProductContainer.jsx
 * @author sandeep & Arish Kumar K
 */

import React, { PureComponent, Fragment, Suspense } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';

import CommonPlugin from '../../common/plugins/CommonPlugin';
import BrazeTemplatePlugin from '../braze/BrazeTemplatePlugin';
import BrazePlugin from '../braze';
import env from '../../common/env';
import PathUtils from '../../common/utils/PathUtils';
import Product from './model/Product';
import Asset from '../courses/model/Asset';
import ProductToken from './model/ProductToken';
import AuthHandler from '../../common/AuthHandler';
import HotspotUtils from '../../common/utils/HotspotUtils';
import MoreMenuHandler from '../../common/MoreMenuHandler';
import PdfHotspot from '../courses/model/PdfHotspot';
import GlossaryHotspot from '../courses/model/GlossaryHotspot';
import LanguageContext from '../../contexts/LanguageContext';
import CommonUtils from '../../common/utils/CommonUtils';
import ErrorCard from '../../common/components/ErrorCard';
import '../../assets/styles/glossaryPopup.css';
import printIcon from '../../common/icons/printIcon';
import $ from 'jquery';
import DeviceManagement from '../../common/components/DeviceManagement';
import AccountWarning from '../../common/components/AccountWarning';
import AccountLock from '../../common/components/AccountLock';

import LaunchCode from '../../common/LaunchCode';
import TextMessage from '../../common/TextMessage';
import * as constants from '../../common/constants';
import LaunchLog from '../../common/model/LaunchLog';
import AccessGranted from '../../common/components/AccessGranted';
import FloatingButton from '../../common/components/FloatingButton';
import BrazeContainer from '../braze/BrazeContainer';
import BrazeUtils from '../../common/utils/BrazeUtils';
import NativeAppBannerPlugin from '../../common/plugins/NativeAppBannerPlugin';
import ProductUtils from '../../common/utils/ProductUtils';
import QRCodeButton from '../../common/components/qrcodebutton/QRCodeButton';
import QRCodeModal from '../../common/components/qrcodemodal/QRCodeModal';

const isIntergratedMLMLaunch = CommonUtils.isIntergratedMLMLaunch();
/**
 * Lazy Loading Components
 */
const HotspotContainer = React.lazy(() => import('../../common/components/HotspotContainer'));
const GlossaryComponent = React.lazy(() => import('../../common/components/GlossaryComponent'));
const VegaReader = CommonUtils.lazyWithPreload(() => import('@vega/vega-reader')
  .then(module => ({ default: module.VegaReader })));

@inject('product', 'productStatus', 'user', 'asset', 'productToken', 'assetStatus',
  'pdfHotspot', 'glossaryHotspot', 'language', 'device', 'launchLog')
@observer
class ProductContainer extends PureComponent {
  static propTypes = {
    product: shapes.modelOf(Product).isRequired,
    productStatus: shapes.state.isRequired,
    assetStatus: shapes.state.isRequired,
    productToken: shapes.modelOf(ProductToken).isRequired,
    user: shapes.user.isRequired,
    ldClient: PropTypes.object,
    device: PropTypes.object.isRequired,
    match: shapes.match.isRequired,
    history: shapes.history.isRequired,
    language: PropTypes.object.isRequired,
    asset: shapes.modelOf(Asset).isRequired,
    pdfHotspot: shapes.modelOf(PdfHotspot).isRequired,
    glossaryHotspot: shapes.modelOf(GlossaryHotspot).isRequired,
    launchLog: shapes.modelOf(LaunchLog).isRequired
  };

  static childContextTypes = {
    preferenceContext: PropTypes.object,
    studyContext: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.brazePlugin = new BrazePlugin(this);
    this.commonPlugin = new CommonPlugin(this, constants.EREADER);
    this.brazeTemplatePlugin = new BrazeTemplatePlugin(this);
    this.nativeAppBannerPlugin = new NativeAppBannerPlugin(this, constants.EREADER);
    const { match } = this.props;
    const { pageId } = match.params;

    this.pageId = pageId;
    this.chapterId = this.commonPlugin.getChapterId();
    this.printPageNumber = this.commonPlugin.getPrintPageNumber();
    this.sourceFrom = this.commonPlugin.getSourceFrom();
    this.IALaunchPageId = '';
    this.isFromCourse = false;
    this.authHandler = new AuthHandler(this);
    this.moreMenuHandler = new MoreMenuHandler(this);
    this.moreMenuData = {};
    this.regionData = null;
    this.glossaryData = null;
    this.isDeeplink = true;
    this.eventTriggered = false;
    this.platformId = PathUtils.getQueryParameterbyName('platformId');
    this.canadianProxy = PathUtils.getQueryParameterbyName('cp');
    this.isET1CourseId = PathUtils.getQueryParameterbyName('isET1CourseId');
    this.tpiValue = PathUtils.getQueryParameterbyName('isTpi');
    this.userPreferredType = PathUtils.getQueryParameterbyName('userPreferredType');
    let drawerState = constants.DRAWER_STATE_CLOSED;
    let selectedPanel = '';
    if (this.userPreferredType && this.userPreferredType !== 'null' && this.userPreferredType !== constants.READ) {
      drawerState = constants.DRAWER_STATE_OPEN;
      selectedPanel = constants.DRAWER_PANEL_STUDY;
      switch (this.userPreferredType) {
        case constants.AUDIO:
          selectedPanel = constants.DRAWER_PANEL_AUDIO;
          break;
        case constants.SOCIAL:
          selectedPanel = constants.DRAWER_PANEL_SOCIAL;
          break;
        case constants.BOOKMARK:
          selectedPanel = constants.DRAWER_PANEL_BOOKMARK;
          break;
        case constants.ACTIVITY_TYPE.CONTENTS:
          selectedPanel = constants.DRAWER_PANEL_CONTENT;
          break;
        case constants.ACTIVITY_TYPE.SEARCH:
          selectedPanel = constants.DRAWER_PANEL_SERACH;
          break;
        case constants.ACTIVITY_TYPE.NOTES:
          selectedPanel = constants.DRAWER_PANEL_NOTEBOOK;
          break;
        default:
          break;
      }
    }
    this.appConfig = null;
    this.serverSideEnabled = false;
    this.isPLAInitiated = false;
    this.userLimitExceed = false;
    this.state = {
      drawerView: {
        drawerState,
        selectedPanel,
        onDrawerStateChange: this.onDrawerStateChange
      },
      openAITutor: {
        isOpen: false
      },
      isDrawerOpen: drawerState === constants.DRAWER_STATE_OPEN,
      showGlossary: false,
      showHotspot: false,
      isInitiated: false,
      setCdnDomain: false,
      addedDeviceId: '',
      removedDeviceId: '',
      open: false,
      openAlert: false,
      openToast: false,
      launchStudyStatus: false,
      isLimitExceeded: false,
      isDeviceRegistered: false,
      isDeviceIncluded: false,
      launchCode: false,
      anchorEl: null,
      showMobileOTPDialog: false,
      otpSent: false,
      textMessageSent: false,
      threatLevel: 0,
      userPreferences: {
        isTextCopyEnabled: false,
        isFirstTimeAITutorUser: null,
        aiTutorAutoOpenCount: 0,
        promptShownCount: 0,
        translationLanguage: null
      },
      flags: {
        basePathChange: true,
        deviceManagementViolation: isIntergratedMLMLaunch ? false : true,
        enableSocial: true,
        scrubberFeature: true,
        showAllCaps: true,
        showColorMode: true,
        showCustomDeck: true,
        showFontOptions: true,
        showHighlights: true,
        showHotSpots: true,
        showKeyTerms: true,
        showLineSpacing: true,
        showPagelayout: true,
        studyGuide: true,
        enableAccountAbuseLevel1: false,
        enableAccountAbuseLevel2: false,
        enablePrintPageNavigation: false,
        showABSearch: false,
        enableCopy: false,
        enableAudioReadAlong: false,
        showABNavigation: false,
        showABAudio: false,
        enableUserLastLocation: false,
        showABNavIcons: false,
        showFlashcardViewInContent: false,
        enabledBronteFormattingOptions: false,
        enablePrintPageNavigationBronte: false,
        showMLMLaunchButton: false,
        showPDFChapterFilter: false,
        showABChannelsIconPosition: false,
        showABAITutor: false,
        showAITutor: false,
        topicTimeOut: constants.TOPIC_TIMEOUT,
        enableFreeResponse: true,
        enableParagraph: false,
        enableStreamingAPI: true,
        delayMessageTimerInSecs: constants.DELAY_TIMER_IN_SECS,
        enableFullStoryRecording: false,
        enableAIShowMore: false,
        enableChannelInSearch: false,
        enableAISleepMode: true,
        showAISleepModeAB: true,
        showAIChannelVideos: false,
        isAIReviewUser: false,
        enableTopicsForChannel: false,
        enableBrazeEReader: false,
        enableDigitalOrPrintPageNavigation: false,
        enableSummaryContinuation: false,
        enableFooterAudio: false,
        miniPlayerHeaderABTestFeature: 0,
        enableAIForGlossary: false,
        enableAIShortAnswer: {},
        showMiniPlayerIconAB: 'A',
        enableProblemSolveFeature: false,
        showSearchChannelVideoChanges: false,
        enableTOCPrintPage: false,
        enableExplainQuizOptions: 1,
        AIChatPlaceHolderText: '',
        showChannelsABHeaderTitle: true,
        showAIExpertDecks: false,
        enableTopicInQuizFlow: '',
        AIStudyToolAutoOpenLimit: 0,
        aitutorWelcomeScreenCohorts: constants.CRUSH_YOUR_FINALS,
        aiTutorAutoOpenCommands: null,
        miniPlayerPaywallTestFeature: 0,
        showSearchChannelVideoPlayer: true,
        enableNativeAppPromoBanners: false,
        showUserHistory: false,
        enableMathMLKeyboard: false,
        enableBundleChannelMiniPlayer: false,
        ChannelsVideoScore: 10,
        starterPromptLimit: 0,
        hideChannelsPopout: false,
        showTranslate: false,
        showRecommendedPrompts: false,
        showSourceReferencePage: false,
        enableQRcode: false,
        AIEnableMarkdown: false,
        isNewFlashcardUIEnable: true,
        AIEnableImageContext: false,
        isSearchStudyAutoSelected: false,
        AIBerlinTurnOffSummary: [],
        AIBerlinTurnOffPractice: []
      },
      accessGranted: false,
      tableOfContents: {},
      LDUserFlagsFetched: false,
      isPageIdSetOnNavChange: false, // Initial VR pageId setted from ereader launch
      brazeContentCards: [],
      brazeContentCardFetched: false,
      brazeInAppMsgFetched: false,
      brazeInitiated: false,
      VRApploaded: false,
      AITutorloaded: false,
      userPreferencesFetched: false,
      // eslint-disable-next-line react/no-unused-state
      nativeAppBannerConfig: {
        showDrawer: false,
        showBanner: false,
        showBannerClose: false
      },
      modalProps: {
        open: false,
        qrCode: '',
        isQRCodeMsg: ''
      }
    };
    this.tocUpdated = false;
    this.isIntegratedFirstTimelaunch = true;
    this.combineStatusSuccess = {
      // combination of userProfile and LD call success
      userProfileAndLD: false,
      // combination of userProfile and Product call success
      userProfileAndProduct: false,
      // combination of LD fetch and product call success
      LDAndProduct: false,
      // combination of combination of LD fetch, product call and pageId fetch on navaigation
      LDAndProductAndPageId: false,
      // combination of LD fetch, product call, VRapp loaded and braze init and content card
      LDAndProductAndBrazeContentCard: false,
      // combination of LD fetch, VRapp loaded, braze init and braze in app msg
      LDAndProductAndBrazeInAppMsg: false,
      // combination of LD fetch, product, braze init
      LDAndProductAndBrazeInit: false,
      // userProfile fetched
      userProfileFetched: false
    };
    BrazeUtils.initializeBrazeCallBackListener((action, actionValue = null)=> {
      switch (action) {
        case constants.BRAZE_CTA_ACTION.COPYURL:
          const product = this.commonPlugin.getProduct();
          const channelId = product.channel_id;
          if (channelId) {
            const channelUrl = `${env.STUDY_CHANNEL_URL}/${channelId}`;
            CommonUtils.copyText(channelUrl);
          }
          break;
        case constants.OPEN_EREADER_COMPONENT:
          if (actionValue) {
            const { component, utmParams } = JSON.parse(actionValue);
            this.commonPlugin.openEreaderComponentFromBraze(component, utmParams);
          }
          break;
        default:
          break;
      }
    });
  }

  componentDidMount() {
    const {
      match: {
        params: {
          id,
          bundleId
        }
      },
      asset,
      product,
      productToken,
      user,
      launchLog,
      productStatus,
      ldClient
    } = this.props;
    CommonUtils.initializeGTM();
    if (isIntergratedMLMLaunch) {
      CommonUtils.initializeIntegratedMLMLaunch(this.commonPlugin.handleIntegratedMLMLaunchMessage);
    }
    product.fetch(id, bundleId);
    Framework.getEventManager().on(constants.USER_PREFERENCES_SUCESS, (data)=> {
      let { userPreferences, userPreferencesFetched } = this.state;
      if(data) {
        const isFirstTimeAITutorUser = data.userActivity ? CommonUtils.getObjectPropertyValue(data.userActivity, 'isFirstTimeAITutorUser', true) : true;
        const aiTutorAutoOpenCount = data.userActivity ? CommonUtils.getObjectPropertyValue(data.userActivity, 'aiTutorAutoOpenCount', 0) : 0;
        const promptShownCount = data.userActivity ? CommonUtils.getObjectPropertyValue(data.userActivity, 'promptShownCount', 0) : 0;
        userPreferences = { ...userPreferences, isFirstTimeAITutorUser, aiTutorAutoOpenCount, promptShownCount };
      } 
      // TODO: Need to refactor code to get and put on user preferencess
      if(data && data.eReaderSettings) {
        const { isTextCopyEnabled, translationLanguage = null } = data.eReaderSettings;
        userPreferences = { ...userPreferences, isTextCopyEnabled, translationLanguage };
      } else {
        const eventData = {
          eventType: 'PUT',
          payload: {
            eReaderSettings: {
              isTextCopyEnabled: true
            }
          }
        }
        userPreferences = { ...userPreferences, isTextCopyEnabled: true };
        Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED, eventData)
      }
      let updateState = { userPreferences };
      if (!userPreferencesFetched) {
        userPreferencesFetched = true;
        updateState = { ...updateState, userPreferencesFetched };
      }
      this.setState({ ...updateState });
    });
    Framework.getEventManager().on(constants.USER_PREFERENCES_FAIL, ()=> {
      this.setState({ userPreferencesFetched: true });
    });
    if (!isIntergratedMLMLaunch) {
      Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED);
    }
    Framework.getEventManager().on(constants.PRODUCT_DATA_FETCHED, () => {
      if (product.subscription && product.subscription.allowAccess && id) {
        productToken.fetch(id);
        asset.fetch(id, null, bundleId, product.format, isIntergratedMLMLaunch);
      }
      CommonUtils.pushGlobalParams();
      // Push telemetry Global params and user telemetry events only once on page load when marin response is ready
      !this.state.isInitiated && !productStatus.isError && this.pushTelemetry(user);
    });

    Framework.getEventManager().on(constants.DEVICE_SWAP_SUCCESS, (data) => {
      if (data.isSwapAlreadyDone) {
        this.setState({ open: true });
      } else {
        this.setState({ open: false });
        this.userLimitExceed = false;
        document.getElementsByClassName('pageNavigation')[0].style.display = 'block';
        this.handleClose();
      }
    });

    /**
     * Pre Loading Vega reader Component
     */
    Framework.getEventManager().on(constants.AUTH_VERIFIED, () => {
      VegaReader.preload();
    });
    Framework.getEventManager().on(constants.USER_PROFILE_FETCHED, () => {
      this.printPageWatermark(user);
      if (user.isImpersonatedId) {
        Framework.getEventManager().publish(constants.LAUNCH_LOG_REQUESTED, () => {
          launchLog.fetch();
        });
        Framework.getEventManager().on(constants.LAUNCH_CODE, () => {
          this.setState({ launchCode: true });
        });
        Framework.getEventManager().on(constants.SEND_CODE_PHONE, (data) => {
          this.setState({ showMobileOTPDialog: true, anchorEl: data.dataEvent });
        });
        Framework.getEventManager().on(constants.OTPREQUESTED, (data) => {
          if(data.type === 'text'){
            this.setState({ textMessageSent: false });
          }else {
          this.setState({ otpSent: false });
          }
        });
        Framework.getEventManager().on('DEVICE_OTP_GENERATE_SUCCESS', (data) => {
          if (data.primaryEmail) {
            this.setState({ otpSent: true });
          } else if (data.phoneNumber) {
            this.setState({ textMessageSent: true });
          }
        });
      }
    });

    Framework.getEventManager().on(constants.BRAZE_IN_APP_MESSAGE_CARD_FETCHED, ()=> {
      this.setState({ brazeInAppMsgFetched: true });
    });
  }

  componentWillUnmount() {
    if (isIntergratedMLMLaunch) {
      CommonUtils.closeConnectionIntegratedMLMLaunch(this.commonPlugin.handleIntegratedMLMLaunchMessage);
    }
  }

  getChildContext() {
    const {
      product: {
        format,
        model,
        id
      },
      product
    } = this.props;
    const {
      showColorMode,
      showLineSpacing,
      showHotSpots,
      showAllCaps,
      showFontOptions,
      showHighlights,
      showPagelayout,
      showCustomDeck,
      showKeyTerms,
      showFlashcardViewInContent,
      enabledBronteFormattingOptions,
      showAITutor,
      showAIExpertDecks,
      isNewFlashcardUIEnable
    } = this.state.flags;
    const isPdf = format === constants.CONTENT_TYPE_PDF;
    const pdfModel = model === constants.ETEXT_PDF;
    const isPXE = format === constants.CONTENT_TYPE_PXE;
    const isBronte = format === constants.CONTENT_TYPE_EPUB;
    const isCITE = format === constants.CONTENT_TYPE_CITE;
    const isBVBronte = ProductUtils.isBVBronteBook(format);
    const isshowFlashcardViewInContentEnabled = (isPXE || isBronte || isCITE || isBVBronte) ? showFlashcardViewInContent : false;

    return {
      preferenceContext: {
        showColorMode: format === constants.CONTENT_TYPE_PXE ? showColorMode : !isPdf,
        showPagelayout: this.serverSideEnabled && showPagelayout,
        showPageProgression: false,
        showAnnotationSwitch: showHighlights,
        showFontOptions: isBronte ? enabledBronteFormattingOptions && showFontOptions : !isPdf && showFontOptions,
        showTextTransform: isBronte ? enabledBronteFormattingOptions && showAllCaps : !isPdf && showAllCaps,
        showLineSpacing: isBronte ? enabledBronteFormattingOptions && showLineSpacing : !isPdf && showLineSpacing,
        showHotSpots: isPdf && showHotSpots,
        showFontSlider: true
      },
      studyContext: {
        glossaryLanguage: pdfModel ? CommonUtils.getGlossaryLanguage(product.getPdfLocale()) : 'en_us',
        showCustomDecks: showCustomDeck,
        showExpertDecks: true,
        showKeyTerms,
        studyLDFlagsContext: {
          showFlashcardViewInContent: isshowFlashcardViewInContentEnabled,
          showAITutor: showAITutor,
          showAIExpertDecks,
          isNewFlashcardUIEnable
        }
      }
    };
  }

  printPageWatermark = (user) => {
    const email = user.email.toUpperCase();
    const truncateData = email.length > 17 ? '...' : '';
    const dynamicEmail = `FOR THE PRIVATE USE OF ${email.substring(0, 17)}${truncateData}`;
    const str = encodeURIComponent(ReactDOMServer.renderToStaticMarkup((printIcon(dynamicEmail))));
    const base64 = `data:image/svg+xml,${str}`;
    const printImage = base64;
    const getHeadTag = window.document.getElementsByTagName('head');
    $(getHeadTag).append(`<style>@media print{@page { size:A4; margin-bottom: 0;} #printImage {display:block; background-image: url(${printImage});background-position: center; background-repeat: no-repeat;background-size: 70%; font-size: 65pt;position: fixed;width: 100%;height: 100%;opacity: 0.6;left: 0%;top:0%;-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}} </style>`);
  }

  /**
  * Handle aysnc api call success
  *
  */
  onCombineStatusSuccess = () => {
    const { user, product } = this.props;
    const { flags, LDUserFlagsFetched, isPageIdSetOnNavChange, VRApploaded, userPreferencesFetched } = this.state;
    const isUserFetched = user && user.gpsSubscriptions;

    // combination of userProfile and LD call combineStatusSuccess
    if (!this.combineStatusSuccess.userProfileAndLD && isUserFetched && this.state.LDUserFlagsFetched) {
      this.commonPlugin.IntialiseAndIdentifyBraze(() => {
        this.setState({ brazeContentCardFetched: true });
      }, flags.enableBrazeEReader);
      this.combineStatusSuccess.userProfileAndLD = true;
    }

    if (!this.combineStatusSuccess.userProfileAndProduct && user && user.gpsSubscriptions && product.id && userPreferencesFetched) {
      this.combineStatusSuccess.userProfileAndProduct = true;
      this.commonPlugin.InitializePendo();
      this.commonPlugin.setGpsSubscriptionOfProduct();
      if (!isIntergratedMLMLaunch) {
        this.updateLaunchDarklyUser();
      }
    }

    if (!this.combineStatusSuccess.LDAndProduct && LDUserFlagsFetched && product.id) {
      this.combineStatusSuccess.LDAndProduct = true;
      const isShowAITutorEnabled = CommonUtils.isAITutorEnabled(flags.showAITutor, flags.isAIReviewUser, product.isAITutorSupported);
      if (flags.enableFullStoryRecording && isShowAITutorEnabled) {
        const script = document.createElement('script');
        script.innerHTML = CommonUtils.getFullStoryScript();
        document.head.appendChild(script);
      }
      this.commonPlugin.initiateDeviceManagementOnEreader();
    }

    if (!this.combineStatusSuccess.LDAndProductAndPageId && (LDUserFlagsFetched || isIntergratedMLMLaunch) && product.id && isPageIdSetOnNavChange) {
      this.combineStatusSuccess.LDAndProductAndPageId = true;
      // GA push for user Access eTextbook from which source
      this.commonPlugin.dispatchEreaderUserPathEvent();
      this.nativeAppBannerPlugin.initializeNativeAppBanners();
    }

    if (!this.combineStatusSuccess.LDAndProductAndBrazeContentCard && this.state.brazeInitiated && VRApploaded && LDUserFlagsFetched && product.id && this.state.brazeContentCardFetched) {
      this.combineStatusSuccess.LDAndProductAndBrazeContentCard = true;
      this.brazeTemplatePlugin.setEReaderContext();
      /**
       * Added 2 seconds delay to show content card after vegareader page load
       */
      setTimeout(() => {
      // Show braze content card
        try {
          this.brazePlugin.showContentCards();
        } catch (error) {
          console.log('braze conent card error', error);
        }
      }, 2000);
    }

    const isAIEnabled = this.commonPlugin.isAIEnabled();
    if (!this.combineStatusSuccess.LDAndProductAndBrazeInit && this.state.brazeInitiated && VRApploaded && LDUserFlagsFetched && flags.enableBrazeEReader && product.id
    && (!isAIEnabled || (isAIEnabled && this.state.AITutorloaded))) {
      this.combineStatusSuccess.LDAndProductAndBrazeInit = true;
      this.brazeTemplatePlugin.setEReaderContext();
      const { BRAZE_TRIGGER_EVENT, BRAZE_CUSTOM_EVENTS: { PPLUS_WEB_EREADER_LOADED } } = constants;

      BrazeUtils.handleEvent(BRAZE_TRIGGER_EVENT, null, null, {
        name: PPLUS_WEB_EREADER_LOADED,
        properties: {}
      });
    }

    if (!this.combineStatusSuccess.LDAndProductAndBrazeInAppMsg && this.state.brazeInitiated && VRApploaded && LDUserFlagsFetched && this.state.brazeInAppMsgFetched) {
      this.combineStatusSuccess.LDAndProductAndBrazeInAppMsg = true;
      this.brazeTemplatePlugin.setEReaderContext();
      try {
        // Show braze in app message
        this.brazePlugin.showInAppMesssage();
      } catch (err) {
        console.log('braze error', err);
      }
    }
  }

  updateLaunchDarklyUser = () => {
    const { user, ldClient } = this.props;
    if (user.id && user.ldUserHash) {
      const customAttributes = {
        hasPPlusSubscription: this.commonPlugin.isPPlusProduct()
      };

      try {
        ldClient.identify({
          kind: 'user',
          key: user.id,
          ...customAttributes
        }, user.ldUserHash, (err, res) => {
          if (res) {
            const aiTutorAutoOpenCommands = this.commonPlugin.getAiTutorAutoOpenCommands(ldClient.variation('aiTutorAutoOpenCommands', {}));
            this.setState({
              flags: {
                basePathChange: res.basePathChange,
                deviceManagementViolation: res.deviceManagementViolation,
                enableSocial: res.enableSocial,
                scrubberFeature: res['scrubber-feature'],
                showAllCaps: res.showAllCaps,
                showColorMode: res.showColorMode,
                showCustomDeck: res.showCustomDeck,
                showFontOptions: res.showFontOptions,
                showHighlights: res.showHighlights,
                showHotSpots: res.showHotSpots,
                showKeyTerms: res.showKeyTerms,
                showLineSpacing: res.showLineSpacing,
                showPagelayout: res.showPagelayout,
                studyGuide: res['study-guide'],
                enableAccountAbuseLevel1: res['enable-account-abuse-level1'],
                enableAccountAbuseLevel2: res['enable-account-abuse-level2'],
                enablePrintPageNavigation: res.enablePrintPageNavigation,
                showABSearch: ldClient.variation('showABSearch', 'false'),
                enableCopy: res.enableCopy,
                enableAudioReadAlong: res.enableAudioReadAlong,
                showABNavigation: ldClient.variation('showABNavigation', 'false'),
                showABAudio: ldClient.variation('showABAudio', 'false'),
                enableUserLastLocation: res.enableUserLastLocation,
                showABNavIcons: ldClient.variation('showABNavIcons', 'false'),
                showFlashcardViewInContent: res.showFlashcardViewInContent,
                enabledBronteFormattingOptions: ldClient.variation('enabledBronteFormattingOptions', 'false'),
                enablePrintPageNavigationBronte: ldClient.variation('enablePrintPageNavigationBronte', 'false'),
                showMLMLaunchButton: ldClient.variation('showMLMLaunchButton', 'false'),
                showPDFChapterFilter: ldClient.variation('showPDFChapterFilter', 'false'),
                showABChannelsIconPosition: ldClient.variation('showABChannelsIconPosition', 'false'),
                showABAITutor: ldClient.variation('showABAITutor', false),
                showAITutor: ldClient.variation('showAITutor', false),
                topicTimeOut: ldClient.variation('topicApiTimeOut', constants.TOPIC_TIMEOUT),
                enableParagraph: ldClient.variation('enableParagraph', 'false'),
                enableFreeResponse: ldClient.variation('enableFreeResponse', 'true'),
                enableStreamingAPI: ldClient.variation('enableStreamingAPI', 'true'),
                delayMessageTimerInSecs: ldClient.variation('delayMessageTimerInSecs', constants.DELAY_TIMER_IN_SECS),
                enableFullStoryRecording: ldClient.variation('enableFullStoryRecording', false),
                enableAIShowMore: ldClient.variation('enableAIShowMore', false),
                enableChannelInSearch: ldClient.variation('enableChannelInSearch', false),
                enableAISleepMode: ldClient.variation('enableAISleepMode', true),
                showAISleepModeAB: ldClient.variation('showAISleepModeAB', true),
                showAIChannelVideos: ldClient.variation('showAIChannelVideos', false),
                isAIReviewUser: ldClient.variation('isAIReviewUser', false),
                enableTopicsForChannel: ldClient.variation('enableTopicsForChannel', false),
                enableBrazeEReader: ldClient.variation('enable_Braze_eReader', false),
                enableDigitalOrPrintPageNavigation: ldClient.variation('enableDigitalOrPrintPageNavigation', false),
                enableSummaryContinuation: ldClient.variation('enableSummaryContinuation',false),
                enableFooterAudio: ldClient.variation('enableFooterAudio', false),
                miniPlayerHeaderABTestFeature: ldClient.variation('miniPlayerHeaderABTestFeature', 0),
                enableAIForGlossary: ldClient.variation('enableAIForGlossary', false),
                enableAIShortAnswer: ldClient.variation('enableAIShortAnswer', {}),
                showMiniPlayerIconAB: ldClient.variation('showMiniPlayerIconAB', 'A'),
                enableProblemSolveFeature: ldClient.variation('enableProblemSolveFeature', false),
                showSearchChannelVideoChanges: ldClient.variation('showSearchChannelVideoChanges', false),
                enableTOCPrintPage: ldClient.variation('enableTOCPrintPage', false),
                enableExplainQuizOptions: ldClient.variation('enableExplainQuizOptions', 1),
                AIChatPlaceHolderText: ldClient.variation('AIChatPlaceHolderText', ''),
                showChannelsABHeaderTitle: ldClient.variation('showChannelsABHeaderTitle', true),
                showAIExpertDecks: ldClient.variation('showAIExpertDecks', false),
                enableTopicInQuizFlow: ldClient.variation('enableTopicInQuizFlowV1', ''),
                AIStudyToolAutoOpenLimit: ldClient.variation('AIStudyToolAutoOpenLimit', 0),
                aitutorWelcomeScreenCohorts: ldClient.variation('aitutorWelcomeScreenCohorts', constants.CRUSH_YOUR_FINALS),
                aiTutorAutoOpenCommands,
                miniPlayerPaywallTestFeature: ldClient.variation('miniPlayerPaywallTestFeature', 0),
                showSearchChannelVideoPlayer: ldClient.variation('showSearchChannelVideoPlayer', true),
                enableNativeAppPromoBanners: ldClient.variation('enableNativeAppPromoBanners', false),
                showUserHistory: ldClient.variation('showUserHistory', false),
                enableMathMLKeyboard: ldClient.variation('enableMathMLKeyboard', false),
                enableBundleChannelMiniPlayer: ldClient.variation('enableBundleChannelMiniPlayer', false),
                ChannelsVideoScore: ldClient.variation('ChannelsVideoScore', 10),
                starterPromptLimit: ldClient.variation('starterPromptLimit', 0),
                hideChannelsPopout: ldClient.variation('hideChannelsPopout', false),
                showTranslate: ldClient.variation('showTranslate', false),
                showRecommendedPrompts: ldClient.variation('showRecommendedPrompts', false),
                showSourceReferencePage: ldClient.variation('showSourceReferencePage', false),
                enableQRcode: ldClient.variation('enableQRcode', false),
                AIEnableMarkdown: ldClient.variation('AIEnableMarkdown', false),
                isNewFlashcardUIEnable: ldClient.variation('isNewFlashcardUIEnable', true),
                AIEnableImageContext: ldClient.variation('AIEnableImageContext', false),
                isSearchStudyAutoSelected: ldClient.variation('isSearchStudyAutoSelected', false),
                AIBerlinTurnOffSummary: ldClient.variation('AI_Berlin_TurnOff_Summary', []),
                AIBerlinTurnOffPractice: ldClient.variation('AI_Berlin_TurnOff_Practice', [])
              },
              LDUserFlagsFetched: true
            });
          } else {
            this.setState({
              LDUserFlagsFetched: true
            });
            console.log('ld error');
          }
        });
      } catch (err) {
        this.setState({
          LDUserFlagsFetched: true
        });
        console.log('ld error');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match, product, asset, user
    } = this.props;
    const { id, bundleId } = match.params;
    this.commonPlugin.updateLangAttribute(); // Update lang atrribute after proper mobx language props update
    if (prevProps.match.params.id !== id) {
      product.fetch(id, bundleId);
      asset.fetch(id, null, bundleId, product.format, isIntergratedMLMLaunch);
    }
    if (user.isImpersonatedId) {
      Framework.getEventManager().publish(constants.LAUNCH_LOG_REQUESTED, () => {
        launchLog.fetch();
      });
    }
  }

  pushTelemetry = (user) => {
    const { product } = this.props;
    const loggedUser = user.id;

    CommonUtils.logUserTelemetryEvent(product, loggedUser);
    this.setState({ isInitiated: true });
  }

  /**
   * Method to Check device already present
   */
  isDeviceIdInList = (deviceId) => {
    const { device } = this.props;
    const matchDevice = device.devices.slice().filter(item => item.deviceId === deviceId);

    return matchDevice.length > 0;
  }

  /**
   * Get config details
   *
   * TODO: Need to remove Product format specific condition once Marin API adds
   * audio flag for all product models
   *
   * @param {string} locale - locale
   * @param {string} bundleId - bundle id
   */
  getConfig = (locale, bundleId, format) => ({
    ...this.props.product.getConfig(locale, bundleId, this.state.flags, this.state.userPreferences, isIntergratedMLMLaunch, this.state.LDUserFlagsFetched),
    ...(this.userLimitExceed && { showDrawer: false })
  });

  /**
   * Get default locale for PDF
   *
   */
  getDefaultLocale = () => {
    const { product } = this.props;
    const { configuration, getPdfLocale } = product;
    const pdfBookLocale = getPdfLocale();
    let locale = Framework.getStoreRegistry().getStore('language').currentLanguage;

    if (configuration.availableBookLanguages
      && configuration.availableBookLanguages.length > 0) {
      if (pdfBookLocale !== constants.DEFAULT_LOCALE && pdfBookLocale !== constants.DEFAULT_LOCALE_US) {
        locale = pdfBookLocale;
      }
    }

    return locale;
  }

  /**
  * Fucntion to pass isFirstTimeAITutorUser to the marin API PUT call
  */
  updateAITutorUserSettings = (data) => {
    CommonUtils.updateUserPreference({ ...data });
  }

  /**
   * Handles navigation
   *
   * @param {*} pageId - page id
   * @param {Object} navData
   */
  handleNavigationChange = (pageId, status, navData) => {
    CommonUtils.checkSession();

    const previousPageId = this.pageId;
    const {
      match,
      product,
      history
    } = this.props;
    if (pageId) {
      const customAsset = [constants.ASSET, constants.STANDARDS, constants.ASSESSMENT, constants.CUSTOM];
      if (pageId && !pageId.type && !customAsset.includes(pageId.type)) {
        CommonUtils.sendCommonPLAEvents(product, pageId, this.isFromCourse, previousPageId, constants.UNLOAD, navData);
      }
      this.isPLAInitiated = true;
      this.pageId = pageId;
      if (!this.state.isPageIdSetOnNavChange) {
        this.setState({ isPageIdSetOnNavChange: true });
      }
      if (product.format === constants.CONTENT_TYPE_PDF || this.serverSideEnabled) {
        this.setState({ showGlossary: false });
        if (pageId && pageId.type && customAsset.includes(pageId.type)) {
          if (pageId.type === constants.CUSTOM) {
            this.setState({ showHotspot: false });
            this.regionData = null;
          } else {
            this.regionData = pageId;
            this.setState({ showHotspot: true });
          }
          this.pageId = previousPageId;
        } else {
          if (pageId !== previousPageId) {
            HotspotUtils.disableNavigation();
          }
          this.setState({ showHotspot: false });
          this.regionData = null;
          PathUtils.redirectToPage(match, history, pageId);
        }
      } else {
        PathUtils.redirectToPage(match, history, pageId);
      }
      if (pageId && !pageId.type && !customAsset.includes(pageId.type)) {
        if (this.isIntegratedFirstTimelaunch && isIntergratedMLMLaunch) {
          // TODO: Need to add specific target origin instead of *
          window.parent.postMessage({
            type: constants.PPLUS_LAUNCH_SUCCEEDED
          }, '*');
          this.isIntegratedFirstTimelaunch = false;
        }

        CommonUtils.sendCommonPLAEvents(product, pageId, this.isFromCourse, previousPageId, constants.LOAD, navData);
        if (!this.state.launchStudyStatus && this.state.drawerView.drawerState === constants.DRAWER_STATE_OPEN
          && this.state.drawerView.selectedPanel === constants.DRAWER_PANEL_STUDY) {
          this.setState({ launchStudyStatus: true });
          CommonUtils.launchStudyPush();
        }
      }
    }
  };

  /**
   * Handles Back button navigation
   *
   */
  handleBackNavClick = () => {
    const {
      match: {
        params: {
          pageId
        }
      },
      user,
      product
    } = this.props;
    const businessModelCode = product.getBusinessModelCode();
    const tpiFlag = (CommonUtils.isGhostAccount() || user.isTpi);
    const previousPageId = pageId;
    CommonUtils.sendCommonPLAEvents(product, '', this.isFromCourse, previousPageId, constants.UNLOAD);
    setTimeout(() => {
      window.location = CommonUtils.redirectToUrl(businessModelCode, tpiFlag, this.sourceFrom);
    }, 1000);
  };

  /**
   * Fetch the hotspot data
   *
   * @param {string} currentPageNo
   * @param {string} nextPageNo
   */
  fetchHotspotData = (currentPageNo, nextPageNo) => new Promise((resolve) => {
    const {
      match,
      pdfHotspot,
      asset,
      product
    } = this.props;
    const { id, bundleId } = match.params;
    const pagePlaylist = (bundleId) ? asset.children : asset.slates;
    const spdfPlaylist = asset.getPagePlaylist(
      bundleId,
      product,
      this.serverSideEnabled
    );
    const bookId = product.bookID || id;

    HotspotUtils.enableNavigation();
    this.pageData = this.serverSideEnabled
      ? spdfPlaylist.find((item => item.id === currentPageNo))
      : pagePlaylist.find((item => item.playOrder === currentPageNo));
    this.pageNumber = this.pageData && this.serverSideEnabled
      ? this.pageData.pagenumber
      : this.pageData && this.pageData.pageno;

    /**
     * User preference change to double page handle
     */
    if (nextPageNo) {
      this.nextPageData = spdfPlaylist.find((item => item.id === nextPageNo));
      const nextPage = this.nextPageData && this.nextPageData.pagenumber;

      this.pageNumber = `${this.pageNumber},${nextPage}`;
    }

    pdfHotspot.fetch(bookId, this.pageNumber);
    const hotspotSubscription = Framework.getEventManager().on(constants.PDF_HOTSPOT_FETCHED, () => {
      resolve(pdfHotspot.constructHotspot(pdfHotspot.hotspot, this.platformId, product.role));
      hotspotSubscription();
    });
  });

  /**
   * Fetch the region data while clicking hotspot
   *
   * @param {string} regionId
   */
  fetchClickedRegionData = (regionId) => {
    const hotspotData = this.props.pdfHotspot.getHotspotData(regionId, this.props.glossaryHotspot);

    this.regionData = hotspotData.regionData;
    this.glossaryData = hotspotData.glossaryData;
    this.setState({
      showHotspot: (hotspotData.hotspotType === 'hotspot'),
      showGlossary: (hotspotData.hotspotType === 'glossary')
    });
  }

  /**
   * Fetch the glossary data
   *
   */
  fetchGlossaryData = () => {
    const { glossaryHotspot, product } = this.props;

    glossaryHotspot.fetch((this.pageNumber).replace(',', '|'),
      product.indexId,
      product.getPdfLocale());
  }

  onDrawerStateChange = (drawerState) => {
    this.setState({ isDrawerOpen: drawerState === constants.DRAWER_STATE_OPEN });
    CommonUtils.checkSession();
  }

  /**
   * Closes PDF glossary popup on click away
   *
   */
  handleGlossaryClose = () => {
    this.setState({ showGlossary: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAlertClose = () => {
    this.setState({ openAlert: false });
  };

  handleConnect = (devId) => {
    if (!this.state.addedDeviceId) { return false; }
    this.setState({ removedDeviceId: devId, openAlert: true });
  };

  handleToastClose = () => {
    this.setState({ openToast: false });
  };

  handleMobileOtpDialogClose = (e) => {
    const target = e.target;
    if (target.classList.contains('paperOtpPopover')) {
      this.setState({ showMobileOTPDialog: true, anchorEl: e.target })
    } else {
      this.setState({ showMobileOTPDialog: false, anchorEl: null })
    }
  }

  handleMenuClose = () => {
    this.setState({ launchCode: false })
  };

  handleAlertAgree = () => {
    const { addedDeviceId, removedDeviceId } = this.state;
    // swap the device
    const payload = { addedDeviceId, removedDeviceId };
    if (!isIntergratedMLMLaunch) {
    Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
      type: 'swap',
      payload
    });
    }
    CommonUtils.deviceDisconnectEvent(addedDeviceId);
    this.handleAlertClose();
  };

  handleDeviceClose = () => {
    this.setState({
      isLimitExceeded: false,
      accessGranted: true
    })
  };

  handleAccountAbuseClose = () => {
    this.setState({
      threatLevel: 0
    });
  }

  handleAccountLockClose = () => {
    CommonUtils.dispatchGaEvent(constants.TL2_CATEGORY, constants.TL2_EVENT_NAME, constants.TL2_EVENT_ACTION, constants.TL2_EVENT_LABEL);
    Framework.logout();
  }

  handleAuthenticatedClose = () => {
    this.setState({accessGranted: false});
    CommonUtils.deviceManagementOTPEvents(constants.LINK_SUCCESS_MODAL, constants.MFA_SUCCESS_MODAL, constants.CLICK_LINK, constants.CLOSE);
};
  /**
   * appLoaded callback from Vega reader component
   *
   */
  appLoaded = (navData) => {
    const {
      match: {
        params: {
          bundleId,
          pageId
        }
      },
      product
    } = this.props;
    this.setState({ VRApploaded: true });

    // Initializing GTM
    CommonUtils.initializeGTM();
    // Initial PLA Load
    if (!this.isPLAInitiated) {
      CommonUtils.sendCommonPLAEvents(product, pageId, this.isFromCourse, '', constants.LOAD, navData);
      if (!this.state.launchStudyStatus && this.state.drawerView.drawerState === constants.DRAWER_STATE_OPEN
        && this.state.drawerView.selectedPanel === constants.DRAWER_PANEL_STUDY) {
        this.setState({ launchStudyStatus: true });
        CommonUtils.launchStudyPush();
      }
    }
  }

  /**
   * Callback function to opena and close the modal
   */
  handleQrCodeClick = (locationInApp) => {
    this.setState(state => ({
      modalProps: {
        ...state.modalProps,
        ...CommonUtils.getModalProps(),
        open: true
      }
    }));
    CommonUtils.fetchUIDQRcode(locationInApp);
  }

  isReadyToRender() {
    const {
      asset, assetStatus, product, productToken, match, history, user
    } = this.props;
    const { pageId = '', bundleId, id } = match.params;
    const {
      isAudioBook, audioBook, role, model
    } = product;
    const isPdf = model === constants.ETEXT_PDF;
    this.onCombineStatusSuccess();

    if (product.format === constants.CONTENT_TYPE_PDF && this.isDeeplink) {
      const page = asset && HotspotUtils.getPlayOrder(asset, bundleId, pageId, this.serverSideEnabled, product);

      this.pageId = page.pageId || '';
      if (page && page.deeplink) {
        this.isDeeplink = false;
      }
    }
    if (asset.id && !assetStatus.isPending && !assetStatus.isError && !this.tocUpdated) {
      this.setState ({
        tableOfContents : isPdf ? asset.getAssetTocPdf(bundleId, role, this.serverSideEnabled, isAudioBook(audioBook)) : asset
      });
      this.tocUpdated = true;
    }
    const isReadyToRender = productToken.verified && product.id && asset.id && (!bundleId || pageId || this.IALaunchPageId);
    Framework.getEventManager().on(constants.PRODUCT_DATA_FETCHED, () => {
      if (!user.isImpersonatedId && !isIntergratedMLMLaunch) {
        // Avoid impersonated users from device count
        const deviceId = window.piSession && window.piSession.getDeviceId();
        Framework.getEventManager().on(constants.DEVICE_LIST_REQUEST_SUCCESS, (data) => {
          const businessModelCode = product.getBusinessModelCode();
          if (CommonUtils.isPplusOrIAbookOrHEI(businessModelCode) && !this.state.isDeviceIncluded) {
            if (this.state.flags.enableAccountAbuseLevel1 || this.state.flags.enableAccountAbuseLevel2) {
              Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                type: 'threatStatus',
                data: { deviceId }
              });
            }
            Framework.getEventManager().on(constants.THREAT_STATUS_SUCCESS, (data) => {
              if (data.threatLevel === 1 && !data.hasUserWarningDisplayed && this.state.flags.enableAccountAbuseLevel1) {
                this.setState({
                  threatLevel: 1
                });
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'warningDisplayed'
                });
              } else if (data.threatLevel === 2 && this.state.flags.enableAccountAbuseLevel2) {
                this.setState({
                  threatLevel: 2
                });
                Framework.getEventManager().on(constants.ACCOUNT_LOCK_SUCCESS, () => {
                  CommonUtils.dispatchGaEvent(
                    constants.OTP_MODAL,
                    constants.LOCK_ACCOUNT_EVENT_NAME,
                    constants.LOCK_ACCOUNT_EVENT_ACTION,
                    constants.LOCK_ACCOUNT_EVENT_LABEL
                  );
                });
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'accountLock',
                  data: {
                    deviceId,
                    userId: user.id
                  }
                });
              }
            });
            this.setState({ addedDeviceId: deviceId });
            // check this device id is already in list
            if (deviceId && !this.isDeviceIdInList(deviceId)) {
              // check if limit reached
              if (data.limitReached && constants.SHOW_DEVICE_MANAGEMENT_POPUP) {
                this.userLimitExceed = true;
                setTimeout(() => {
                  const elements = document.getElementsByClassName('pageNavigation');
                  elements.length && (elements[0].style.display = 'none');
                }, 3000);
                // call open popup
                this.setState({ open: true });
              } else {
                // Register the device
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'register',
                  payload: deviceId
                });
              }
            }
            if (this.state.flags.deviceManagementViolation) {
              Framework.getEventManager().on(constants.DEVICE_REGISTER_SUCCESS, (data) => {
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'violation',
                  data: { deviceId }
                });
              });
              if (deviceId && this.isDeviceIdInList(deviceId)) {
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'violation',
                  data: { deviceId }
                });
              }
              Framework.getEventManager().on(constants.DEVICE_VIOLATION_SUCCESS, (data) => {
                if (data.isWebViolation) {
                  Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                    type: 'generateOTP',
                    data: {
                      clientId: env.CLIENT_ID,
                      deviceId
                    }
                  });
                  CommonUtils.deviceManagementReSentOTPEvents(constants.SEND_OTP);
                }
                this.setState({
                  isLimitExceeded: data.isWebViolation
                });
              });
            }
            Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
              type: 'list',
              payload: { includeDeviceDetails: true }
            });
            this.setState({
              isDeviceIncluded: true
            })
          }
        });
      }
    });

    if (isReadyToRender) {
      if (history.action === constants.POP) {
        const title = product.title;
        const userId = user.id;

        product.doSubscriptionCheck(
          title,
          userId,
          id
        );
      }
      const productId = product.bookID || product.id;
      window.localStorage.setItem(`bookId_${product.id}`, productId);
    }

    return isReadyToRender;
  }

  /**
   * Callback function to close the modal
   */
   handleQRCodeClose = () => {
    if (CommonUtils.controller) {
      CommonUtils.controller.abort();
    }
    this.setState(state => ({
      modalProps: {
        ...state.modalProps,
        open: !state.modalProps.open
      }
    }));
  }

  render() {
    const {
      asset,
      assetStatus,
      language,
      match,
      product,
      productStatus,
      device,
      user // eslint-disable-line no-unused-vars
    } = this.props;
    const { bundleId, id, pageId } = match.params;
    const {
      format, indexId, version, model, backLinkUrl
    } = product;
    const {
      tableOfContents
    } = this.state;
     const {
       smsUserId, initials, username, phoneNumber, mobileAccess
     } = Framework.getStoreRegistry().getStore('user');
    const { itemsCode } = Framework.getStoreRegistry().getStore('launchLog');
    const isPdf = model === constants.ETEXT_PDF;
    const errorCode = (productStatus.error && constants.ERROR_CODE_MARIN)
      || (assetStatus.error && constants.ERROR_CODE_PRISM);
    const contentType = format === constants.CONTENT_TYPE_EPUB ? constants.CONTENT_TYPE_EPUB3 : format;

    this.serverSideEnabled = product.getServerSideStatus();
    let pdfCallbacks;

    const tocContent = tableOfContents && tableOfContents.children && tableOfContents.children.length > 0 && CommonUtils.tocList(tableOfContents.children, pageId);
    const brazeAINotificationCard = this.state.brazeContentCards.length > 0 && this.state.brazeContentCards[0];
    document.title = tocContent ? tocContent.title || tocContent.label : CommonUtils.getAppTitle();

    if (isPdf || this.serverSideEnabled) {
      pdfCallbacks = {
        hotspotObj: {
          fetchHotspotData: this.fetchHotspotData,
          bookFeatures: this.props.product.getBookFeatures(),
          fetchClickedRegionData: this.fetchClickedRegionData,
          renderGlossaryData: this.fetchGlossaryData
        }
      };
    }

    this.moreMenuData = {
      handleChange: this.moreMenuHandler.handleChange,
      menuItem: this.moreMenuHandler.getMenuItems(this.canadianProxy, null, this.appConfig, this.isFromCourse, bundleId, this.state.otpSent, this.state.textMessageSent),
      userInitial: initials
    };

    const isET1CourseId = this.isET1CourseId ? `&isET1CourseId=${this.isET1CourseId}` : '';

    if (pageId === undefined && bundleId && asset.id) {
      let firstPageId = asset && asset.children.length && asset.children[0].id;

      if (isPdf) {
        firstPageId = asset && asset.children.length && asset.children[0].playOrder;
        this.pageId = firstPageId;
      }

      if (isIntergratedMLMLaunch && bundleId && format !== constants.CONTENT_TYPE_PXE) {
        const productDetails = CommonUtils.deCodeBase64(bundleId);
        if (productDetails && productDetails.startPage) {
          this.IALaunchPageId = productDetails.startPage;
          if (isPdf) {
            this.pageId = productDetails.startPage; // for pdf IA launch
          }
        }
      } else {
        const canadianProxy = this.canadianProxy ? `&cp=${this.canadianProxy}` : '';
        const type = this.userPreferredType ? `&userPreferredType=${this.userPreferredType}` : '';
        const isLMS = CommonUtils.isLMSQueryParamAvailable() ? `&${constants.IS_LMS}=Y` : '';

        Framework.redirectTo(`/products/${id}/bundles/${bundleId}/pages/${firstPageId}?platformId=${this.platformId}${canadianProxy}${isET1CourseId}${type}${isLMS}`);
      }
    }
    // Get the pdf locale
    const pdfLocale = isPdf ? this.getDefaultLocale() : '';
    this.appConfig = this.getConfig(pdfLocale, bundleId, format);
    const productId = product.id || id;

    if (productStatus.isError && !this.eventTriggered) {
      const data = {
        api: constants.MARIN,
        errorDetails: productStatus
      };

      this.eventTriggered = true;
      product.sendErrorEvents(data, productId);
    }
    if (assetStatus.isError && !this.eventTriggered) {
      const data = {
        api: constants.ASSET,
        data: assetStatus
      };

      this.eventTriggered = true;
      product.sendErrorEvents(data, productId);
    }

    return (
      <Fragment>
        {!isIntergratedMLMLaunch ? this.commonPlugin.renderPrintMessage() : null}
        <LoadingHandler
            loading={productStatus.isPending || assetStatus.isPending}
            isError={productStatus.isError || assetStatus.isError}
            error={productStatus.error || assetStatus.error}
          content={this.isReadyToRender() && (
              <Fragment>
                <LanguageContext.Consumer>
                  {(context) => {
                    if (isPdf) {
                      context.onLocaleChange(pdfLocale);
                    }

                    return (
                      <Suspense fallback={<CircularProgress className="circleStyle" />}>
                        {this.nativeAppBannerPlugin.renderMobileBanners()}
                        <VegaReader
                          bookId={product.id}
                          chapterId={this.chapterId}
                          secondaryBookId={product.bookID}
                          businessModelCode={product.getBusinessModelCode()}
                          tocData={tableOfContents}
                          pagePlaylist={asset.getPagePlaylist(
                            bundleId,
                            product,
                            this.serverSideEnabled
                          )}
                          theme={CommonUtils.getAppTheme()}
                          contentType={this.serverSideEnabled ? constants.CONTENT_TYPE_SPDF : contentType}
                          pdfCallbacks={pdfCallbacks}
                          pageId={isPdf ? this.pageId : (pageId || this.IALaunchPageId)}
                          onBackNavClick={this.handleBackNavClick}
                          onNavigationChange={this.handleNavigationChange}
                          authHandler={this.authHandler.get('ProductContainer')}
                          env={env.ENVIRONMENT}
                          basePath={product.getBasePath(this.state.flags.basePathChange, this.serverSideEnabled)}
                          indexId={indexId}
                          versionUrn={version}
                          config={this.getConfig(pdfLocale, bundleId, format)}
                          bookInfo={product.getBookInfo(this.printPageNumber)}
                          moreMenuData={!isIntergratedMLMLaunch && this.moreMenuData}
                          appLoaded={this.appLoaded}
                          drawerView={!isIntergratedMLMLaunch && this.state.drawerView}
                          openAITutor={this.state.openAITutor}
                          onBotClose={() => this.setState({
                            openAITutor: {
                              isOpen: false
                            }
                          })}
                          onLDTrack={this.commonPlugin.handleLDTrackEvent}
                          updateAITutorUserSettings={this.updateAITutorUserSettings}
                          onInitBraze={this.commonPlugin.onBrazeEventFromReader}
                          menuIconsEnd={this.state.flags.enableQRcode && !mobileAccess
                            && (
                            <QRCodeButton
                              title={CommonUtils.getModalProps().title}
                              onQrCodeClick={this.handleQrCodeClick}
                            />
                            )}
                          getPendoData={this.commonPlugin.onPendoMetaFromReader}
                        />
                      </Suspense>
                    );
                  }}
                </LanguageContext.Consumer>
              </Fragment>
            )}

            loadingContent={
              <CircularProgress className="circleStyle" />
            }
            errorContent={
              <ErrorCard code={errorCode} productId={id} />
            }
          />
          {this.state.openToast && (
            <div className="alert-wrapper">
              <Alert
                onClose={this.handleToastClose}
              >
                <h3>Device activated</h3>
                <p>You won't be able to swap devices again until next month.</p>
              </Alert>
            </div>
          )}
          {this.state.isLimitExceeded ? <DeviceManagement dialogOpen={true} onClose={this.handleDeviceClose} /> : ''}
          {this.state.accessGranted ? <AccessGranted open={true} onClose={this.handleAuthenticatedClose}/> : ''}
          {this.state.threatLevel === 1 ? <AccountWarning open={true} onClose={this.handleAccountAbuseClose} /> : ''}
          {this.state.threatLevel === 2 ? <AccountLock open={true} onClose={this.handleAccountLockClose} /> : ''}
          {this.state.showHotspot && (
            <Suspense fallback={<CircularProgress className="circleStyle" />}>
              <HotspotContainer
                basePath={backLinkUrl}
                goToPageNumber={this.handleNavigationChange}
                isStudent
                productId={product.id}
                regionData={this.regionData}
                userId={smsUserId}
                pagePlaylist={asset.getPagePlaylist(
                  bundleId,
                  product,
                  this.serverSideEnabled
                )}
                isET1CourseId={isET1CourseId}
                bookData={product}
                userName={username}
              />
            </Suspense>
          )}
          {this.state.showGlossary && (
            <Suspense fallback={<CircularProgress className="circleStyle" />}>
              <GlossaryComponent
                glossaryData={this.glossaryData}
                onClickAway={this.handleGlossaryClose}
                regionData={this.regionData}
                showGlossary={this.state.showGlossary}
              />
            </Suspense>
          )}
          {this.state.launchCode && (
            <LaunchCode
              open={this.state.launchCode}
              email={user.email}
              onClose={this.handleMenuClose}
              history={itemsCode}
            />
          )}
          {this.state.showMobileOTPDialog && (
              <TextMessage
                open={this.state.showMobileOTPDialog}
                anchorEl={this.state.anchorEl}
                onClose={this.handleMobileOtpDialogClose}
                phoneNo={phoneNumber}
              />
            )}
          <div>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={`dialog-zindex popup-backgroundImage ${device.isSwapAlreadyDone ? 'swap-background' : ''}`}
            >
              <IconButton aria-label="close" className="closeButton" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
              {this.state.openAlert && (
                <IconButton aria-label="close" className="arrowBackButton" onClick={this.handleAlertClose}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <DialogTitle id="alert-dialog-title">
                {device.isSwapAlreadyDone
                  ? (
                    <div>
                      <h3><FormattedMessage {...language.getText('device.SWAPPED_ALREADY')} /></h3>
                      <p><FormattedMessage {...language.getText('device.CONTACT_SUPPORT')} /></p>
                    </div>
                  )
                  : this.state.openAlert
                    ? (
                      <div>
                        <h3><FormattedMessage {...language.getText('device.SWITCH_DEVICE')} /></h3>
                        <p><FormattedMessage {...language.getText('device.SWITCH_ONCE')} /></p>
                      </div>
                    )
                    : (
                      <div>
                        <h3><FormattedMessage {...language.getText('device.SEAT_TAKEN')} /></h3>
                        <p><FormattedMessage {...language.getText('device.DISCONNECT_DEVICE')} /></p>
                      </div>
                    )
                }
              </DialogTitle>
              {!device.isSwapAlreadyDone && !this.state.openAlert && (
                <div>
                  <DialogContent>
                    <LoadingHandler
                      loading={device.isLoading}
                      content={!device.isSwapAlreadyDone && device.devices.slice().map(item => (
                        <div className="devicelist-container">
                          <div className="list-left">
                            <p>{item.displayName}</p>
                          </div>
                          <div className="list-right">
                            <Button
                              variant="outlined"
                              onClick={() => this.handleConnect(item.deviceId)}
                              color="primary"
                            >
                              <FormattedMessage {...language.getText('device.DISCONNECT')} />
                            </Button>
                          </div>
                        </div>
                      ))}
                      loadingContent={
                        <CircularProgress />
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <p className="tagline"><FormattedMessage {...language.getText('device.ALERT_TEXT')} /></p>
                  </DialogActions>
                </div>
              )}
              {this.state.openAlert && (
                <DialogActions className="alert_continue_button">
                  <Button onClick={this.handleAlertAgree} color="primary" autoFocus>
                    <FormattedMessage {...language.getText('device.CONTINUE')} />
                  </Button>
                </DialogActions>
              )
              }
              {device.isSwapAlreadyDone && (
                <DialogActions />
              )}
            </Dialog>
          </div>
          {brazeAINotificationCard && (
              <BrazeContainer
                data={brazeAINotificationCard?.data}
                callbackForBraze={brazeAINotificationCard?.callbackForBraze}
              />
            )}
          {(isIntergratedMLMLaunch && this.state.flags.showMLMLaunchButton) && (
            <FloatingButton />
          )}
          {this.state.modalProps.open && (
          <QRCodeModal
            data={this.state.modalProps}
            callbacks={{
              handleQrCodeClick: this.handleQRCodeClose
            }}
          />
          )}
      </Fragment>
    );
  }
}

ProductContainer.defaultProps = {
  ldClient: {}
};

export default isIntergratedMLMLaunch ? ProductContainer : withLDConsumer({ clientOnly: true })(ProductContainer);
