/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Auth home FooterIllustration Component
 *
 * @file FooterIllustration.jsx
 * @author Vipin R
 */

import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import FooterStyles from './FooterStyles';

const FooterIllustration = ({ classes }) => (
  <div className={classes.footerDiv}> </div>
);

FooterIllustration.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(FooterStyles)(FooterIllustration);
