/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/* eslint-disable max-len */

/**
 * QRCodeIcon icon
 *
 * @file QRCodeIcon.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import React from 'react';

const QRCodeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon">
      <path id="Vector" d="M3.363 3.364V9.727H9.726V3.364H3.363ZM11.089 12.909V22H2V12.91L11.089 12.909ZM9.726 14.273H3.363V20.636H9.726V14.273ZM7.994 16.146V18.873H5.267V16.146H7.994ZM11.089 2V11.09H2V2H11.089ZM7.994 5.237H5.267V7.964H7.994V5.237ZM21.993 18.932V21.999H17.383V20.635H20.629V18.932H21.993ZM15.843 20.633V21.997H12.906V20.633H15.843ZM14.268 12.903V18.902H12.905V12.903H14.268ZM17.538 16.146V17.508H18.904V18.871H16.175V16.146H17.538ZM22 15.85V17.379H20.637V15.849L22 15.85ZM21.99 12.943V14.307H17.386V12.943H21.99ZM21.996 2V11.09H12.907V2H21.996ZM20.633 3.364H14.271V9.727H20.633V3.364ZM18.901 5.237V7.964H16.174V5.237H18.901Z" fill="currentColor" />
    </g>
  </svg>
);

export default QRCodeIcon;

