/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { PENDO_PLATFORM, AUTH_HOME } from '../constants';
import env from '../env';
import CommonUtils from './CommonUtils';

/**
 * Pendo utilities
 *
 * @file PendoUtils.js
 * @author PAPPU NIKHIL KUMAR
 */

export default class PendoUtils {
  /**
   * Function to Initialize pendo
   * @param {*} user
   *
   */
  static handleInitialization(user, location = AUTH_HOME, customFields = { }) {
    const pendo = window.pendo;
    if (pendo) {
      pendo.initialize({
        visitor: {
          id: user.id,
          platform: PENDO_PLATFORM[location],
          ...customFields
        },
        disablePersistence: true,
        disableCookies: true,
        analytics: {
          excludeEvents: ['load', 'click', 'focus', 'submit', 'change', 'track']
        },
        events: {
          ready: () => {
            pendo.startSendingEvents();
          }
        }
      });
    } else {
      PendoUtils.injectScript().then(() => {
        PendoUtils.handleInitialization(user, location, customFields);
      });
    }
  }

  /**
   * Function to inject Pendo script file
   *
   */
  static injectScript() {
    return new Promise(((resolve) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';

      // Pendo initialization code snippet
      script.innerHTML = `(function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];

          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {

            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
          })(v[w]);

          y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';

          z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
      })('${env.PENDO_API_KEY}');`;

      script.async = true;
      script.defer = true;
      document.getElementsByTagName('head')[0].appendChild(script);
      resolve();
    }));
  }

  static updateOptions = (user, location = AUTH_HOME, customFields = { }) => {
    const pendo = window.pendo;
    if (pendo && customFields && !CommonUtils.isEmpty(customFields)) {
      pendo.updateOptions({
        visitor: {
          id: user.id,
          platform: PENDO_PLATFORM[location],
          ...customFields
        }
      });
    }
  }
}
