/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to courseCollection
 *
 * @file courseCollection.js
 * @author Mohamed yasar arabath M
 */

import { types, applySnapshot, getSnapshot } from 'mobx-state-tree';

const Collection = types.model('Collection', {
  productId: types.maybeNull(types.string),
  courseId: types.maybeNull(types.string),
  bookId: types.maybeNull(types.string),
  template: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  channelMappingType: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  subStartDate: types.maybeNull(types.string),
  bookCoverImage: types.maybeNull(types.string),
  bmc: types.maybeNull(types.string)
});

const Course = types.model(
  'Course',
  {
    channelId: types.maybeNull(types.string),
    imageSrc: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    active: types.optional(types.boolean, false),
    isChannelMapped: types.optional(types.boolean, false),
    isChannelOnly: types.optional(types.boolean, false),
    isMLMcourse: types.optional(types.boolean, false),
    collection: types.array(Collection)
  }
);

const CourseCollection = types.model('CourseCollection', {
  courses: types.array(Course),
  activeTab: types.maybeNull(types.string)
}).views(self => ({
  getCourseList() {
    return getSnapshot(self.courses);
  }
})).actions(self => ({
  set(courses, activeTab) {
    applySnapshot(self.courses, courses);
    // eslint-disable-next-line no-param-reassign
    self.activeTab = activeTab;
  },
  setActiveTab(activeTab) {
    // eslint-disable-next-line no-param-reassign
    self.activeTab = activeTab;
  }
}));

export default CourseCollection;
