/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */
/**
 * Footer styles
 *
 * @file Footerstyles.jsx
 * @author Poornima N
 * @since 0.0.1
 */
import mixins from '../../assets/styles/mixins.scss';

const FooterStyles = () => ({
  footerContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    bottom: 0,
    zIndex: '3',
    width: '100%',
    minHeight: '293px',
    background: '#F7F9FD',

    '@media screen and (min-width: 833px)': {
      minHeight: '190.91px'
    },
    '@media screen and (min-width: 1194px)': {
      minHeight: '132px'
    }
  },
  footercontainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '30px',
    width: '100%',
    height: '100%',
    padding: '20px 16px',

    '@media screen and (min-width: 1195px)': {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '20px 32px'
    },
    '@media screen and (min-width: 1440px)': {
      padding: '20px 72px'
    }
  },
  listDiv: {
    flexGrow: 1
  },
  footerList: {
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '30px',

    '@media screen and (min-width: 1195px)': {
      justifyContent: 'center',
      gap: 20
    }
  },
  listItemStyle: {
    padding: 0,
    cursor: 'pointer',
    width: 'auto'
  },
  footerapp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  footersection: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Hind'
  },
  footercopyrightsection: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Hind',
    whiteSpace: 'nowrap'
  },
  footerlinkApp: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '8px',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  downloadAppIcon: {
    flexShrink: 0
  },
  footerDiv: {
    background: '#020917',
    position: 'relative',
    display: 'flex',
    height: 92
  },
  footerContentWrapperVariantb: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    bottom: 0,
    zIndex: '3',
    width: '100%',
    background: '#F7F9FD',
    padding: 16,
    boxSizing: 'border-box',

    '@media screen and (min-width: 768px)': {
      padding: 24
    },
    '@media screen and (min-width: 1024px)': {
      padding: '40px 24px',
      minHeight: '100px'
    },
    '@media screen and (min-width: 1440px)': {
      padding: '40px 32px',
      minHeight: '132px'
    }
  },
  footercontainerVariantb: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    '@media screen and (min-width: 1024px)': {
      flexDirection: 'row'
    }
  },
  footerListVariantb: {
    display: 'flex',
    padding: 0,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '8px',

    '@media screen and (min-width: 1024px)': {
      flexDirection: 'row',
      gap: '16px'
    }
  },
  footersectionVariantb: {
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0.24px',
    fontFamily: mixins.fontFamilyTT,
    lineHeight: '20px',
    color: mixins.darkThemeColor
  },
  footercopyrightsectionVariantb: {
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0.24px',
    fontFamily: mixins.fontFamilyTT,
    lineHeight: '20px',
    color: mixins.copyright,
    alignSelf: 'flex-end',
    whiteSpace: 'nowrap',
    '@media screen and (min-width: 1024px)': {
      alignSelf: 'auto'
    }
  },
  listDivVariantb: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

export default FooterStyles;
